import React, { Fragment, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn } from 'ahooks';
import { DatePicker, Input, Form, Row, Col } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import InputFileUpload from '../../../components/InputFileUpload/InputFileUpload';
import { onSubmitRequired, onBlurValidateDirtyword } from './patternRex';

const D6Verification = React.forwardRef((_, ref) => {
	const { t } = useTranslation();

	let fileUploadRef = useRef(null);

	return (
		<div ref={ref}>
			<Row>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d6_verification"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder={t('8D.Verification')} className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d6_responsible"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder={t('8D.Responsible')} className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						label=""
						name="d6_datedue"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateDue')}
							format="YYYY-MM-DD"
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item
						label=""
						name="d6_datecompl"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateCompleted')}
							format="YYYY-MM-DD"
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
			</Row>
			<li>
				<InputFileUpload ref={fileUploadRef} />
			</li>
		</div>
	);
});

export default memo(D6Verification);
