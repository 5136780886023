import React, { useState, useEffect, useRef, Fragment } from 'react';
import './index.scss';
import LeftDetail from './detail';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import { Input, Modal, Form } from 'antd';
import ICONinfo from '../../assets/icons/info-i.svg';
import '../../../node_modules/antd/lib/input/style/index.css';
import '../../../node_modules/antd/lib/modal/style/index.css';
import '../../../node_modules/antd/lib/form/style/index.css';
import Tab1 from './components/Tab1';
import Tab2 from './components/Tab2';
import { getQueryVariable } from '../../utils/util';
// import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useMount, useReactive } from 'ahooks';
import { useTranslation } from 'react-i18next';
import Print from './components/Print';
import moment from 'moment';
import _ from 'lodash';

function DReport({ bgHandler }) {
	const { t } = useTranslation();

	const [isModalVisible, setIsModalVisible] = useState(true);
	const [expandedSupplier, setExpandedSupplier] = useState(false);

	// const location = useLocation();

	const tab1Ref = useRef();
	const tab2Ref = useRef();
	const printRef = useRef(null);

	const renderData = useReactive({
		tabShow: 1,
		topFormError: false,
		form1Error: false,
		form2Error: false,
	});

	const form1Data = useReactive({
		d8_communicate: '',
		d8_prepared: '',
		d8_datecompl: '',
	});

	const form2Data = useReactive({
		d8_communicate: '',
		d8_prepared: '',
		d8_datecompl: '',
	});

	const [formtop] = Form.useForm();
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();

	useEffect(() => {
		bgHandler(false, false, IMAGlandingBig, false);
	}, [bgHandler]);

	useMount(() => {
		const formDataTemp = _.cloneDeep(form2Data);
		form2.setFieldsValue(formDataTemp);
		// date
		form2.setFieldsValue({
			d8_datecompl: moment('2015/01/01', 'YYYY-MM-DD'),
		});
	});

	const checkUser = async (federation, requestOptions) => {
		const temp = await fetch(federation, requestOptions);
		const userTemp = await temp.json();
		console.log(userTemp);
		if (temp.status === 400) {
			console.log(userTemp.error_description);
			// Federation Service Error
			return;
		}
		//
		let decode = {};
		try {
			decode = jwtDecode(userTemp.access_token);
		} catch (error) {
			//
			return;
		}
		console.log(decode);
		if (decode.mail === 'jonathan.poh@basf.com') {
			setIsModalVisible(false);
		}
		return;
	};

	const onSearch = (value) => {
		// goFed();
		if (value === '8dreport') {
			setIsModalVisible(false);
		}
	};

	const changeTab = (val) => {
		renderData.tabShow = val;
	};

	const draft = () => {
		try {
			const values = form2.getFieldsValue();
			console.log('Success:', values);
			//
			let obj = {};
			obj.ncNumber = '80000088888';
			// D4D5
			obj.defineRootCausePermanentCorrectiveActions = {};
			// select
			if (values.d4d5_rac === 'other') {
				obj.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsed =
					values.d4d5_rac;
			} else {
				obj.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsed =
					values.d4d5_rac;
			}
			obj.defineRootCausePermanentCorrectiveActions.DefineRootCausePermanentCorrectiveActions =
				[];
			// Root Cause --- Permanent Correct Action
			for (let i = 0; i < values.d4d5.length; i++) {
				let d4d5obj = {};
				d4d5obj.DefineRootCause = values.d4d5[i].d4_define;
				d4d5obj.PermanentCorrectiveActions = [];
				for (let j = 0; j < values.d4d5[i].d5.length; j++) {
					let d5obj = {};
					d5obj.PermanentCorrectiveAction = values.d4d5[i].d5[j].d5_permanent;
					d5obj.Responsible = values.d4d5[i].d5[j].d5_responsible;
					d5obj.DueDate = values.d4d5[i].d5[j].d5_datedue
						? moment(values.d4d5[i].d5[j].d5_datedue).format('YYYY-MM-DD')
						: '';
					d5obj.CompleteDate = values.d4d5[i].d5[j].d5_datecompl
						? moment(values.d4d5[i].d5[j].d5_datecompl).format('YYYY-MM-DD')
						: '';
					d4d5obj.PermanentCorrectiveActions.push(d5obj);
				}
				obj.defineRootCausePermanentCorrectiveActions.DefineRootCausePermanentCorrectiveActions.push(
					d4d5obj
				);
			}
			// D6. Verification of Effectiveness
			obj.verificationOfEffectiveness = {
				VerificationOfEffectiveness: values.d6_verification,
				Responsible: values.d6_responsible,
				DueDate: values.d6_datedue
					? moment(values.d6_datedue).format('YYYY-MM-DD')
					: '',
				CompleteDate: values.d6_datecompl
					? moment(values.d6_datecompl).format('YYYY-MM-DD')
					: '',
			};
			// D7. Prevention
			obj.prevention = {
				Prevention: values.d7_prevention,
				Responsible: values.d7_responsible,
				DueDate: values.d7_datedue
					? moment(values.d7_datedue).format('YYYY-MM-DD')
					: '',
				CompleteDate: values.d7_datecompl
					? moment(values.d7_datecompl).format('YYYY-MM-DD')
					: '',
			};
			// D7. Prevention - Other Areas Affected
			if (values.d7_choice === 'yes') {
				obj.prevention.OtherAreasAffected = 'true';
				obj.prevention.ProcessFMEA = values.d7_1;
				obj.prevention.Form = values.d7_2;
				obj.prevention.ProductFMEA = values.d7_3;
				obj.prevention.Specification = values.d7_4;
				obj.prevention.ControlPlan = values.d7_5;
				obj.prevention.PPAP = values.d7_6;
				obj.prevention.InspectionPlan = values.d7_7;
				obj.prevention.JobInstruction = values.d7_8;
				obj.prevention.Procedure = values.d7_9;
				obj.prevention.Other = values.d7_10;
			} else if (values.d7_choice === 'no') {
				obj.prevention.OtherAreasAffected = 'no';
				obj.prevention.ReasonExplained = values.d7_11;
			} else {
				obj.prevention.OtherAreasAffected = '';
			}
			// D8. Communicate Results and Recognize Team
			obj.communicateResultsRecognizeTeam = {
				CommunicateResultsRecognizeTeam: values.d8_communicate,
				PreparedBy: values.d8_prepared,
				CompleteDate: values.d8_datecompl
					? moment(values.d8_datecompl).format('YYYY-MM-DD')
					: '',
			};
			console.log(obj);
			//
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			renderData.topFormError = true;
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
		}
	};

	const submit = async () => {
		if (renderData.tabShow === 1) {
			try {
				const result = await Promise.all([
					formtop.validateFields(),
					form1.validateFields(),
				]);
				console.log(result);
				console.log('验证成功');
			} catch (errorInfo) {
				console.log(errorInfo);
				scrollTop();
				renderData.topFormError = true;
				//
				for (let i = 0; i < errorInfo.errorFields.length; i++) {
					if (errorInfo.errorFields[i].name[0].includes('d1_')) {
						tab1Ref.current.D1error();
					}
					// if (errorInfo.errorFields[i].name[0].includes('d2_')) {
					// 	tab1Ref.current.D2error();
					// }
					if (errorInfo.errorFields[i].name[0].includes('d3_')) {
						tab1Ref.current.D3error();
					}
				}
				//
				setTimeout(() => {
					renderData.topFormError = false;
				}, 5000);
			}
		} else {
			try {
				const result = await Promise.all([
					formtop.validateFields(),
					form2.validateFields(),
				]);
				console.log(result);
				console.log('验证成功');
			} catch (errorInfo) {
				console.log(errorInfo);
				scrollTop();
				renderData.topFormError = true;
				//
				for (let i = 0; i < errorInfo.errorFields.length; i++) {
					if (errorInfo.errorFields[i].name[0].includes('d45_')) {
						tab2Ref.current.D45error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d6_')) {
						tab2Ref.current.D6error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d7_')) {
						tab2Ref.current.D7error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d8_')) {
						tab2Ref.current.D8error();
					}
				}
				//
				setTimeout(() => {
					renderData.topFormError = false;
				}, 5000);
			}
		}

		return;
		try {
			const values = await formtop.validateFields();
			console.log('Success1:', values);
			await aaa();
		} catch (err) {
			console.log('Failed:', err);
			renderData.topFormError = true;
			scrollTop();
			// setTimeout(() => {
			// 	document.querySelector('.ant-form-item-has-error').scrollIntoView({
			// 		block: 'start',
			// 		inline: 'nearest',
			// 		behavior: 'smooth',
			// 	});
			// }, 100);
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
		}

		return;

		try {
			const values = await formtop.validateFields();
			console.log('Success1:', values);
			throw 'myException';
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			renderData.topFormError = true;
			scrollTop();
			// setTimeout(() => {
			// 	document.querySelector('.ant-form-item-has-error').scrollIntoView({
			// 		block: 'start',
			// 		inline: 'nearest',
			// 		behavior: 'smooth',
			// 	});
			// }, 100);
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
			throw new Error(errorInfo);
		}
		try {
			const values = await form1.validateFields();
			console.log('Success2:', values);
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			console.log('Failed:', errorInfo.errorFields);
			renderData.form1Error = true;
			scrollTop();
			setTimeout(() => {
				renderData.form1Error = false;
			}, 5000);
			for (let i = 0; i < errorInfo.errorFields.length; i++) {
				if (errorInfo.errorFields[i].name[0].includes('d2_')) {
					tab1Ref.current.D2error();
				}
				if (errorInfo.errorFields[i].name[0].includes('d1_')) {
					tab1Ref.current.D1error();
				}
				if (errorInfo.errorFields[i].name[0].includes('d3_')) {
					tab1Ref.current.D3error();
				}
			}
		}
	};

	const aaa = async () => {
		try {
			const values = await form1.validateFields();
			console.log('Success2:', values);
		} catch (err) {
			console.error('inner', err);
		}
	};

	const scrollTop = (number = 0, time) => {
		if (!time) {
			document.body.scrollTop = document.documentElement.scrollTop = number;
			return number;
		}
		const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
		let spacingInex = time / spacingTime; // 计算循环的次数
		let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
		const everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
		const scrollTimer = setInterval(() => {
			if (spacingInex > 0) {
				spacingInex--;
				scrollTop((nowTop += everTop));
			} else {
				clearInterval(scrollTimer); // 清除计时器
			}
		}, spacingTime);
	};

	const print = () => {
		printRef.current.print();
	};

	return (
		<Fragment>
			<Print ref={printRef} />
			<section className="dreport print_hide">
				<Modal
					style={{
						maxWidth: '100vw',
						top: 0,
						paddingBottom: 0,
					}}
					className="passmodal"
					bodyStyle={{
						height: 'calc(100vh - 48px)',
					}}
					width="100vw"
					open={isModalVisible}
					footer={null}
					closable={false}
				>
					<div
						className="passwrap"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<div
							className="passwordwrap"
							style={{ width: '400px', marginTop: '20px' }}
						>
							<Input.Search
								placeholder="input password"
								allowClear
								enterButton="Submit"
								size="large"
								type="password"
								onSearch={onSearch}
							/>
						</div>
					</div>
				</Modal>
				<div className="supplier">
					<div className="boxHeadSupplier">
						<span>{t('8D.Date')}</span>
						<h3>09-08-2021</h3>
						<button
							className={
								expandedSupplier
									? 'btnToggleSupplier expanded'
									: 'btnToggleSupplier'
							}
							onClick={() => setExpandedSupplier(!expandedSupplier)}
						>
							<img className="expand" alt="Expand" src={ICONdown} />
							<img className="shrink" alt="Shrink" src={ICONup} />
							<span className="lessInfo">show less details</span>
							<span className="moreInfo">show more details</span>
						</button>
					</div>

					<LeftDetail expandedSupplier={expandedSupplier}></LeftDetail>
				</div>
				<div className="dfeedback">
					<span className="status open">
						<span>{t('Detail.Open')}</span>
					</span>
					<Form.Provider>
						<div>
							{renderData.topFormError ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<div className="formtopwrap">
								<Form
									size="large"
									className="reportform"
									name="formtop"
									autoComplete="off"
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									form={formtop}
								>
									<span className="antd_form_title">
										{t('8D.SupplierMaterialNo')}
									</span>
									<Form.Item label="" name="SupplierMaterialNo">
										<Input
											className="antd_input"
											placeholder={t('8D.SupplierMaterialNo')}
										/>
									</Form.Item>
									<span className="antd_form_title">
										{t('8D.SupplierMaterialName')}
									</span>
									<Form.Item label="" name="Supplier Material Name">
										<Input
											className="antd_input"
											placeholder={t('8D.SupplierMaterialName')}
										/>
									</Form.Item>
								</Form>
							</div>
							<div className="tabwrap">
								<span
									className={
										renderData.tabShow === 1 ? 'tabitem current' : 'tabitem'
									}
									onClick={() => changeTab(1)}
								>
									{t('8D.Acknowledgement')}
								</span>
								<span
									className={
										renderData.tabShow === 2 ? 'tabitem current' : 'tabitem'
									}
									onClick={() => changeTab(2)}
								>
									{t('8D.Investigation')}
								</span>
							</div>
							{renderData.form1Error ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<Form
								size="large"
								name="form1"
								className={
									renderData.tabShow === 1
										? 'reportform formshow'
										: 'reportform formhide'
								}
								autoComplete="off"
								form={form1}
							>
								<Tab1 form={form1} ref={tab1Ref} />
							</Form>
							{renderData.form2Error ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<Form
								size="large"
								name="form2"
								className={
									renderData.tabShow === 1
										? 'reportform formhide'
										: 'reportform formshow'
								}
								autoComplete="off"
								form={form2}
							>
								<Tab2 form={form2} ref={tab2Ref} />
							</Form>
						</div>
					</Form.Provider>
					<div className="boxActions">
						<button className="btnCancel">{t('Detail.Cancel')}</button>
						<button className="btnPrint" onClick={print}>
							{t('Detail.Print')}
						</button>
						{renderData.tabShow === 1 ? (
							<Fragment />
						) : (
							<button className="btnSave" onClick={() => draft()}>
								{t('Detail.Saveasdraft')}
							</button>
						)}
						<button className="btnSend" onClick={() => submit()}>
							{t('Detail.Save')}
						</button>
					</div>
				</div>
			</section>
		</Fragment>
	);
}

export default DReport;
